* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.58;
    color: #333;
    /*  background-color: #f4f4f4; */
    height: 100%;


    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body, button, input, keygen, select, textarea {
    font-family: -apple-system, BlinkMacSystemFont, open_sanslight, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.clearfix:after {
    display: block;
    content: "";
    clear: both;
}

.hidden {
    display: none;
}

.form-control {
    width: 100%;
    min-height: 38px;
    font-size: 15px;
    border: 1px solid #c8c8c8;
    margin-bottom: 15px;
    border-radius: 5px;
}


#usernameForm input.form-control {
    min-height: 48px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.form-control.error {
    border-color: #ff0000;
}

.form-group {
    margin-bottom: 15px;
}

input {
    padding-left: 10px;
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 20px;
}

h1 {
    font-size: 1.7em;
}

a {
    color: #128ff2;
}

button {
    border: none;
    background: transparent;
    color: white;
    outline: none;
    vertical-align: middle;
    padding: 0.6rem 1rem;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

button.default {
    background-color: transparent;
    color: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

button.primary {
    background-color: transparent;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
}

button.accent {
    background-color: transparent;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
}


.wrapper {
    background-image: url("./images/convay-background.png");
    background-repeat: repeat;
    height: 100vh;
    width: 100%;
}

.username-page-container .title {
    font-size: 18px;
    margin-top: 0;
}

.videoPlayer {
    margin: 0 auto;
    position: relative;
}

.username-page-container {
    background: #F7F7FA;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    height: 275px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.form-label {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
}

.username-page-container .username-submit {
    background: #365D7E;
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
}

.username-page-container .username-submit:disabled {
    background: #617781;
    cursor: none;
}

.overlay .videoPlayer:after {
    content: "";
    background-color: #39525f;
    background-image: url("./images/convay-background.png");
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.videoPlayer:after {
    content: "";
    background: transparent;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.iframe-pre-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 250px;
    width: 250px;
    background: transparent;
    z-index: 99;
    color: #ffffff;
    margin: auto;
    top: -75px;
    left: 0;
    bottom: 0;
    right: 0;
}


.transparent-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
}

#chat-page {
    position: relative;
    height: 100%;
    display: flex;
}

.chat-container {
    height: 100%;
    position: absolute;
    width: 315px;
    right: 0;
    top: 0;
    background: #ffffff;
    padding: 20px;
    z-index: 100;
}

#chat-page ul {
    list-style-type: none;
    margin: 0;
    overflow: auto;
    height: calc(100% - 90px);
    padding: 0;
    align-items: baseline;
    display: flex;
    flex-direction: column;
}

#chat-page #messageForm {
    position: absolute;
    bottom: 0;
    width: 100%;
}

#chat-page ul li {
    padding: 15px 0;
}

#chat-page .event-message {
    width: 100%;
    text-align: center;
    clear: both;
}

#chat-page .event-message p {
    color: #faf5f5;
    font-size: 14px;
    word-wrap: break-word;
}

.chat-display-name span {
    margin-left: 10px;
}

#chat-page .chat-message i {
    width: 35px;
    height: 35px;
    font-size: 18px;
    line-height: 35px;
    color: #333333;
    text-align: center;
    border-radius: 50%;
    font-style: normal;
    text-transform: uppercase;
}

.chat-display-name {
    color: #18171a;
    font-size: 14px;
    font-weight: 600;
    padding-top: 2px;
    display: flex;
    align-items: center;
}

.chat-message .chat-individual-message {
    color: #18171a;
    background: #f1f1f1;
    padding: 8px 12px;
    border-radius: 8px;
    margin-left: 45px;
    margin-right: 5px;
    word-break: break-word;
}

.chat-message-self .chat-individual-message {
    background: #365d7e;
    padding: 8px 12px;
    border-radius: 8px;
    margin-right: 5px;
    color: #ffffff;
    word-break: break-word;
}

li.chat-message-self {
    display: flex;
    justify-content: end;
    width: 100%;
}

#messageForm #message {
    -ms-overflow-style: none;
    border: 0 none;
    border-radius: 6px;
    box-shadow: none;
    text-align: left;
    font-size: 14px;
    color: #737987;
    opacity: 1;
    padding: 12px 10px;
    overflow-y: auto;
    scrollbar-width: none;
    word-break: break-word;
    width: calc(100% - 40px);
    background: #ebeff2;
}

#messageForm .input-group button {
    width: 80px;
    height: 38px;
    margin-left: 5px;
    background: #365d7e;
}

.chat-header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.chat-header svg {
    width: 18px;
    cursor: pointer;
}

.chat-header-content {
    font-size: 16px;
}

.chat-header h2 {
    margin: 0;
    font-weight: 500;
    color: #ffffff;
}

.connecting {
    padding-top: 5px;
    text-align: center;
    color: #777;
    position: absolute;
    top: 65px;
    width: 100%;
}

/* update */

.own-message {
    text-align: right;
}

.own-message i {
    visibility: hidden !important;
}


/*  Toolbox  */

.toolbox {
    position: absolute;
    transition: bottom .3s ease-in;
    width: 100%;
    z-index: 9999;
    bottom: 0;
}

.toolbox-content {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 16px;
    position: relative;
    z-index: 250;
    pointer-events: none;
}

.toolbox-content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;
    justify-content: center;
    pointer-events: all;
    border-radius: 6px;
}


.first-portion {
    min-width: 28%;
    display: flex;
    justify-content: space-around;
    margin-right: 5px;
}

.second-portion {
    height: 54px;
    min-width: 45%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    opacity: 1;
}

.third-portion {
    min-width: 25%;
    display: flex;
    justify-content: flex-end;
}

.first-portion-2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.first-portion-3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 0;
}

.toolbox-content .toolbox-button-wth-dialog {
    display: inline-block;
    text-transform: capitalize;
}

.information-preview {
    float: left;
    width: 30px;
    text-align: left;
    margin-left: 1px;
    z-index: 1000;
}

.toolbox-button {
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: 48px;
}

.toolbox-icon {
    width: 48px;
    height: 48px;
    display: flex;
    font-size: 24px;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
}

.toolbar-button-with-badge .badge-counter {
    font-size: 10px;
    line-height: 13px;
    min-width: 19px;
    pointer-events: none;
    position: absolute;
    right: 1px;
    background: #c7ccd9;
    opacity: 1;
    border-radius: 20px;
    padding: 3px;
    text-align: center;
    bottom: 30px;
}

.toolbar-button-with-badge .chat-count-label {
    font-size: 10px;
    line-height: 13px;
    min-width: 19px;
    pointer-events: none;
    position: absolute;
    right: 1px;
    background: #c7ccd9;
    opacity: 1;
    border-radius: 20px;
    padding: 3px;
    text-align: center;
    bottom: 30px;
}




.toolbar-button-with-badge .badge-counter .badge-counter-text {
    text-align: center;
    letter-spacing: 0;
    color: #142434;
    opacity: 1;
}

.base-middle-part {
    display: flex;
    background: #192c36;
    border-radius: 10px;
    box-shadow: 0 2px 1px #192c36;
    height: 58px;
}

.first-part-middle-part {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    align-items: end;
}

.second-part-middle-part {
    margin-left: 1px;
}

.third-part-middle-part {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 20px;
}


.toolbar-button-with-badge {
    display: inline-block;
    position: relative;
}

.toolbar-button-with-badge .badge-round {
    bottom: 50px;
    font-size: 12px;
    line-height: 17px;
    min-width: 17px;
    pointer-events: none;
    position: absolute;
    right: 14px;
}

.reactions-menu-popup-container {
    display: inline-block;
    position: relative;
}


.reactions-menu {
    margin-bottom: 22px;
    background: #f2f2f2;
    box-shadow: 0 3px 16px rgba(0, 0, 0, .6), 0 0 4px 1px rgba(0, 0, 0, .25);
    border-radius: 3px;
    padding: 16px;
    position: absolute;
    top: -100px;
    width: max-content;
    left: -140px;
}


.hangup-button {
    margin-right: 10px;
    width: 127px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    box-shadow: 0 2px 1px #122228 !important;
    max-width: 65px;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-box {
    background: white;
    border-radius: 8px;
    text-align: center;
    padding: 20px; /* Add padding for better spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.dialog-box button {
    margin: 10px;
    padding: 10px; /* Increase padding for better click area */
    width: 200px; /* Set a fixed width */
    height: 40px; /* Set a fixed height */
    border: none;
    border-radius: 5px;
    background-color: #ec6e60; /* Change background color for visibility */
    color: white; /* Change text color to white */
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s; /* Add transition for hover effect */
}

.dialog-box button:hover {
    background-color: #d43e2a; /* Darker shade on hover */
}

.no-button {
    background-color: white !important; /* Force background to white */
    color: #000 !important; /* Force text color to black */
    border: 1px solid black !important; /* Set border to black and increase thickness */
    padding: 10px; /* Ensure padding for better click area */
    width: 200px !important; /* Set the same width as the other button */
    height: 40px !important; /* Set the same height as the other button */
    border-radius: 5px; /* Match the border radius */
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s; /* Add transition for hover effect */
}

.no-button:hover {
    background-color: #6da0c9 !important;
}

.subject-timer {
    margin-left: 50px;
    width: 109px;
    display: inline-block;
    font-size: 48px;
    color: #eaecf0;
    line-height: 16px;
    min-width: 45px;
    vertical-align: super;
    float: left;
    text-align: left;
    letter-spacing: 0;
    opacity: 1;
    margin-bottom: 4px;
    font-weight: 100;
}


.common-background-for-middle-part {
    background: transparent linear-gradient(0deg, #233d48 0, #223e4b 100%) 0 0 no-repeat padding-box;
    opacity: 1;
    padding: 11px;
    box-shadow: 0 2px 1px #192c36;
    padding-top: 8px;
    display: flex !important;
    justify-content: center !important;
    align-content: center !important;
}

.request-to-talk-btn {
    background: #656E72;
    color: #ffffff;
    display: flex;
    padding: 6px 12px;
    align-items: center;
    margin: 0 20px;
    border-radius: 7px;
    cursor: pointer;
}

.request-to-talk-btn span {
    margin-left: 7px;
    font-size: 15px;
}

.request-to-talk-btn svg {
    width: 15px;
}


.request-to-talk-btn.request-to-talk-btn-disabled {
    opacity: 0.3;
    cursor: default;
}

.chat-effect .toolbox-button .toolbox-icon:hover .jitsi-icon > svg > g > g {
    stroke-width: 2.8;
}

.emoji-effect .toolbox-button .toolbox-icon:hover .jitsi-icon > svg > g > rect {
    stroke-width: 2.8;
}

.participant-effect .toolbox-button .toolbox-icon:hover .jitsi-icon > svg > g > g > path {
    stroke-width: 2.8;
}

.videoPlayer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 70px;
    right: 0;
    margin: auto;
}

.video-container {
    position: relative;
    width: 100%;
}

.video-container.panel-open {
    width: calc(100% - 315px);
}

.participants-panel {
    overflow: hidden;
    transition: width .16s ease-in-out;
    width: 315px;
    background: #ffffff;
    height: 100%;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}

.participants-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.participants-header-content {
    color: #4f555e;
    font-size: 16px;
}

.participants-header svg {
    width: 18px;
    cursor: pointer;
}

.participant-joined {
    background: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
}

.participants-entry {
    padding-top: 10px;
    padding-bottom: 12px;
    display: flex;
    width: 100%;
    font-size: 13px;
    align-items: center;
}

.participants-avatar {
    position: relative;
    width: 20px;
    margin-right: 10px;
    margin-left: 4px;
    padding-left: 15px;
}

.participants-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 110px;
    text-align: left;
    font: normal normal 13px/17px Nunito Sans;
    letter-spacing: 0;
    color: #3e3e3e;
    opacity: 1;
}

.participants-filter input {
    border: 1px solid #ebf4f9;
    background: #ffffff;
    border-radius: 9px;
    color: #737987;
    padding: 12px;
    width: 100%;
    box-shadow: 0 0 3px #eee;
    margin-bottom: 10px;
    margin-top: 15px;
    transition: 0.3s;
}

.participants-filter input:focus {
    border: 1px solid #2dbfbe;
}

.participant-joined-counter {
    text-align: left;
    background: #2dbfbe;
    border-radius: 4px;
    color: #ffffff;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.participant-list {
    margin-top: 15px;
    height: calc(100vh - 225px);
    overflow: auto;
}

.single-participant {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.participant-avatar {
    background: #2dbfbe;
    width: 35px;
    height: 35px;
    font-size: 15px;
    text-align: center;
    line-height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    text-transform: uppercase;
}

.participant-name {
    color: #030506;
    line-height: 18px;
    font-size: 15px;
}

.participant-name span {
    display: block;
    font-size: 13px;
    color: #757575;
}

.toaster-wrapper {
    background: #ffffff;
    position: absolute;
    bottom: 100px;
    left: 30px;
    display: flex;
    padding: 20px 20px 20px 15px;
    border-radius: 6px;
    border-left: 7px solid #B5EDB5;
    z-index: 100;
    min-width: 275px;
}

.toaster-wrapper.reject {
    border-left: 7px solid #E7453C;
}



.toaster-negative-wrapper {
    background: #ffffff;
    position: absolute;
    bottom: 100px;
    left: 30px;
    display: flex;
    padding: 20px 20px 20px 15px;
    border-radius: 6px;
    border-left: 7px solid #e45252;
    z-index: 100;
    min-width: 275px;
}

.toaster-close {
    position: absolute;
    top: 15px;
    right: 10px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}

.toaster-close:hover {
    background: #F4F7F8;
}

.toaster-close svg {
    width: 18px;
}

.toaster-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.toaster-title {
    color: #030506;
    font-size: 16px;
    font-weight: 400;
}

.toaster-content {
    color: #757575;
    font-size: 15px;
}

.toaster-btns {
    display: flex;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 300;
}

.toaster-btns svg {
    width: 15px;
    margin-right: 5px;
}

.toaster-btn-deny {
    background: #FCE9E8;
    padding: 4px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: 95px;
    justify-content: center;
    cursor: pointer;
    color: #E7453C;
}

.toaster-btn-accept {
    background: #365D7E;
    padding: 4px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: 95px;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    margin-left: 7px;
}

.pre-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgba(55, 81, 94, 0.8);
    z-index: 999;
    color: #ffffff;
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
    box-sizing: border-box;
}

.lds-spinner {
    color: currentColor;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3.2px;
    left: 36.8px;
    width: 6.4px;
    height: 17.6px;
    border-radius: 20%;
    background: currentColor;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.notification {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
}

.notification span {
    margin-bottom: 15px;
    font-size: 16px;
}

.notification button {
    padding: 8px 16px;
    background-color: #365D7E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.notification button:hover {
    background-color: #2c4d6a;
}

.input-group {
    position: relative;
}

.smile-icon {
    position: absolute;
    right: 45px;
    top: -25%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* update end  */
@media screen and (max-width: 730px) {

    .chat-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 480px) {
    .chat-container {
        height: calc(100% - 30px);
    }

    .username-page-container {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
        padding: 25px;
    }

    #chat-page ul {
        height: calc(100% - 120px);
    }

    #messageForm .input-group button {
        width: 65px;
    }

    #messageForm .input-group input {
        width: calc(100% - 70px);
    }

    .chat-header {
        padding: 10px;
    }

    .connecting {
        top: 60px;
    }

    .chat-header h2 {
        font-size: 1.1em;
    }
}

.error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 5px;
    display: block;
}

.information-preview {
    position: absolute;
    text-align: left;
    margin: 1px;
}

#information-preview-content {
    position: absolute;
    background-color: #fff;
    width: 350px;
    border-radius: 10px;
    padding: 30px;
    display: block;
    bottom: calc(100% + 10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    left: 13px;
    font-size: 14px;
}

.meeting-credential {
    margin-bottom: 20px;
}

.meeting-credential-title {
    color: #6f7687;
    padding-bottom: 8px;
}

.meeting-credential-value {
    color: #444544;
    font-weight: 600;
}

.meeting-link-title {
    color: #030506;
    margin-bottom: 5px;
}

.meeting-link-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.meeting-link-url {
    border-radius: 8px;
    padding: 8px 12px;
    color: #142434;
    overflow-wrap: break-word;
    word-break: break-all;
    border: 1px solid #C1C1C1;
    background: #EFEFEF;
}

.meeting-link-copy-button {
    cursor: pointer;
    color: #365D7E;
    font-size: 14px;
    width: 45px;
}

.participant-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.raised-hand-icon {
  margin-right: 5px;
}

.screen-share-icon {
  width: 15px;
  height: 15px;
  margin-left: auto;
}

.participant-name {
  position: relative;
}
